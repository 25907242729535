



























































import { Component, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import http from '@/shared/http'
import { debounce } from 'throttle-debounce'
import __ from '@/shared/helpers/__'
import Model from '@/shared/classes/model'
import SelectItem from '@/shared/classes/form/fields/Select/select-item'
import IResponse from '@/shared/interfaces/modules/response.interface'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import AbstractField from '@/shared/classes/form/fields/abstract-field'
import { IFilter } from '@/shared/interfaces/classes/form.interfaces'
import { getQueryName } from '@/shared/helpers/query.helper'

@Component({
  methods: {
    __,
    _get: _.get
  }
})
export default class MultipleSearchableField extends AbstractField {
  items: SelectItem[] = []
  searchKeyword: string|null = null
  selectedItems: SelectItem[] = []
  page: number = 1
  lastPage: number = 1
  loading: boolean = false
  initialLoaded: boolean = false
  insideChange: boolean = false
  debouncedSearch = debounce(400, this.search)
  @Watch('items') private watchItemsChanges(): void {
    this.field.items = this.items
  }
  @Watch('searchKeyword') private handleSearchKeywordChanges(): void {
    if (this.searchKeyword === null) {

      return
    }
    this.loading = true
    this.page = 1
    this.lastPage = 1
    this.debouncedSearch()
  }

  @Watch('value') private handleValueChange(value: any, oldValue: any): void {
    if (value && oldValue && (value.join(',') === oldValue.join(','))) return
    this.page = 1
    this.lastPage = 1
    if (! this.insideChange) this.loadPredefined()
    if (this.insideChange) this.debouncedSearch()
    this.insideChange = false
  }

  created() {
    this.loadPredefined()
  }

  async loadStartingData() {
    this.page = 1;
    this.lastPage = 1;

    if (this.searchKeyword === null) {
      await this.search()
      return
    }

    if (this.initialLoaded || (this.value && this.value.length > 0)) return

    this.initialLoaded = true
    await this.search()
  }

  changeValue(value: string[]): void {
    this.removeError()
    this.selectedItems = this.items.filter((item: SelectItem) => value.some((element: string) => element === item.value))
    this.lastPage = 1
    this.page = 1
    this.searchKeyword = null
    this.value = value
    this.items = _.orderBy(this.items, (item: SelectItem) => ! value.some((element: string) => element === item.value))
    if (this.value.length === 0) {
      this.initialLoaded = false
      this.loadStartingData()
    }
  }

  get showLoadMore(): boolean {
    return this.page < this.lastPage
  }

  loadMore(): void {
    this.page++;
    this.search(true)
  }

  async setValue(value: string) {
    const initialLoaded = this.value.length !== 0
    this.insideChange = true
    this.value = [...this.value, value]
    await this.loadPredefined()
    this.initialLoaded = initialLoaded
  }

  isSelected(item: SelectItem): boolean {
    return this.value?.some((value: string) => value === item.value)
  }

  get autocompleteMenuProps(): any {
    let defaultProps: any = {
      maxHeight: 304,
    };

    if (this.$vuetify.breakpoint.smAndDown) {
      defaultProps.maxHeight = 130;
    }

    return defaultProps;
  }

  get attachId(): string {
    return `attach-${ this.form ? _.snakeCase(this.form.formId) : '' }-${ _.snakeCase(this.fullKey) }`
  }

  private search(loadMore: boolean = false): void {
    this.loading = true
    http.get(this.generateEndpoint())
      .then((response: IResponse<Array<Model<IModelResponse>>>) => {
        let items = response.data.data
          .map((item: Model<IModelResponse>) => new SelectItem()
            .setValue(_.get(item, this.field.loadItemsProps.value))
            .setTitle(_.get(item, this.field.loadItemsProps.title))
            .setDeletedAt(_.get(item, 'deleted_at'))
          )

        if (loadMore) {
          items = [...this.items, ...items]

          if (response.data.data.length >= this.field.loadItemsProps.perPage) {
            this.lastPage++;
          }
        } else {
          if (response.data.data.length >= this.field.loadItemsProps.perPage) {
            this.lastPage = 2;
          }
        }

        this.items = [...this.selectedItems, ...items]

        setTimeout(() => {
          const autocomplete: any = this.$refs.autocomplete
          if (! autocomplete) return

          autocomplete.onScroll()
          autocomplete.updateMenuDimensions()
        }, 100)
      })
      .finally(() => this.loading = false)
  }

  private async loadPredefined(): Promise<any> {
    if ((! this.value) || this.value.length === 0) return
    this.loading = true
    await http.get(`${ this.field.loadItemsProps.endpoint }?filter-in-${ this.field.loadItemsProps.value }=${ this.value.join(',') }&per_page=900`)
      .then((response: IResponse<Array<Model<IModelResponse>>>) => response.data.data)
      .then((items: Array<Model<IModelResponse>>) => {
        this.selectedItems = items.map((item: Model<IModelResponse>) => new SelectItem()
          .setValue(_.get(item, this.field.loadItemsProps.value))
          .setTitle(_.get(item, this.field.loadItemsProps.title))
          .setDeletedAt(_.get(item, 'deleted_at'))
        )
        this.items = [
          ...this.selectedItems,
          ...this.items,
        ]
        this.initialLoaded = true
        this.search()
      })
      .catch(() => this.value = [])
      .finally(() => this.loading = false)
  }

  private generateEndpoint(): string {
    const searchParams = new URLSearchParams()

    if (this.searchKeyword) searchParams.set(`filter-like-${ this.field.loadItemsProps.title }`, this.searchKeyword)
    if (this.selectedItems.length > 0) searchParams.set(`filter-notIn-${ this.field.loadItemsProps.value }`, this.selectedItems.map((item: SelectItem) => item.value).join(','))
    searchParams.set('per_page', this.field.loadItemsProps.perPage.toString())
    searchParams.set('page', this.page.toString())

    if (this.field.loadItemsProps.filters) {
      this.field.loadItemsProps.filters.forEach((filter: IFilter) => {
        searchParams.set(getQueryName(filter.type, filter.name), filter.value)
      })
    }

    if (this.field.loadItemsProps.params) {
      Object.keys(this.field.loadItemsProps.params).forEach((key: string) => {
        searchParams.set(key, this.field.loadItemsProps.params[key])
      })
    }

    let endpoint = this.field.loadItemsProps.endpoint
    if (this.field.loadItemsProps.additionalEndpoint) endpoint + `/${ this.field.loadItemsProps.additionalEndpoint}`

    return endpoint + `?${ searchParams.toString() }`
  }
}
